import React, { useContext, useEffect, memo } from 'react';
import { redirectWhenStatusPageIsNotValid, navigateToError } from '../../utils/navigate';
import { CreditcardContext } from '../../context/creditcard.context';
import { isGreaterThan, diffTwoDatesInSeconds as diff, isTrue, isUndefined } from '../../utils/functions';
import SECURE_CODE from '../../constants/security.json';
import { SecurityForm } from '../../components/forms/account-lock';
import Layout from '../../components/responsive/layout';
import { Counter } from '../../components/counter';
import { LoadingComponent } from '../../components/loading';
import { LangContext } from '../../context/lang.context';
import { CompanyContext } from '../../context/company.context';
import { useStaticQuery, graphql } from 'gatsby';
import Portrait from '../../components/responsive/portrait';
const RenderLayout = memo(
  ({ render, token, data, img, command }: SecurePage) => {
    if (!render) {
      return <LoadingComponent />;
    }
    const { t } = useContext(LangContext);
    return (
      <Layout>
        <Portrait>
          <article className="content">
            <article>
              <p className="text-center text-white font-thin m-0 leading-none">
                {t(data).SECURITY.SUBTITLE_1} <b className="font-bold">{t(data).SECURITY[SECURE_CODE[command]]}</b>{' '}
                {t(data).SECURITY.SUBTITLE_2}
              </p>
              <h1 className="text-center text-white font-thin mt-3 leading-tight text-3xl md:text-4xl">
                {t(data).SECURITY.TITLE_1} <b className="font-bold">{t(data).SECURITY.TITLE_2}</b>
              </h1>
              <img className="w-full my-6" loading="eager" src={img} />
            </article>
            <Counter visible={false} height="0" paddingBottom="3" />
          </article>
        </Portrait>
        <SecurityForm token={token} command={command} />
      </Layout>
    );
  },
  ({ render: prev }, { render: next }) => prev === next
);

const AccountLock = ({ getToken, location = {} }: PageProps) => {
  const { infoUser, timeStamp, getTokenLock, loading } = useContext(CreditcardContext);
  const allValid = [Object.entries(infoUser).length, timeStamp?.length ?? 0].every(isGreaterThan(0));
  const token: string = getToken();

  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          SECURITY {
            SUBTITLE_1
            SUBTITLE_2
            TITLE_1
            TITLE_2
            UNLOCK
            LOCK
          }
        }
      }
    }
  `);
  const { getPublicURL } = useContext(CompanyContext);
  const pin = getPublicURL(`/theme/assets/images/responsive/pin/card.svg`);

  useEffect(() => {
    if (!infoUser?.expirationTime && token) {
      getTokenLock?.(token);
    } else if (isUndefined(token)) {
      navigateToError()?.();
    }
  }, []);

  useEffect(() => {
    if (allValid) {
      redirectWhenStatusPageIsNotValid(infoUser, timeStamp);
    }
  }, [infoUser, timeStamp]);

  const dateDiff = diff(timeStamp ?? '', infoUser?.expirationTime ?? '');
  const render = [allValid, !!dateDiff, !infoUser.status, !loading].every(isTrue);
  return (
    <RenderLayout render={render} token={token} data={data} location={location} img={pin} command={infoUser.command} />
  );
};

export default AccountLock;
