import React, { useState, useContext } from 'react';
import { useFormik, FormikValues } from 'formik';
import { css } from '@emotion/react';
import * as Yup from 'yup';
import { FormProps } from './models';
import { ArticleStyled } from './styles';
import { Input } from '../inputs';
import security from '../../constants/security.json';
import pages from '../../constants/pages.json';
import creditCardService from '../../services/endpoints';
import { createMask } from '../../utils/masks';
import { navigateToFailed, navigateToSuccess } from '../../utils/navigate';
import { useStaticQuery, graphql } from 'gatsby';
import { LangContext } from '../../context/lang.context';
import Button from '../button/button';

const SecurityForm = ({
  token,
  command,
}: FormProps & { command: 'lock_account' | 'unlock_account'; token: string }) => {
  const { t } = useContext(LangContext);

  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          SECURITY {
            FORM {
              VALIDATION {
                PIN
              }
              BUTTON
            }
          }
          SITE {
            LOCK {
              FORM {
                STEP_02 {
                  LABEL
                }
              }
            }
          }
        }
      }
    }
  `);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      pin: '',
    },
    validationSchema: Yup.object({
      pin: Yup.string().min(4, t(data).SECURITY.FORM.VALIDATION.PIN).required(t(data).SECURITY.FORM.VALIDATION.PIN),
    }),
    onSubmit: async (values: FormikValues) => {
      if (submitting) {
        return;
      }

      setSubmitting(true);

      try {
        await creditCardService.patchLock(values, token);
        const state = { from: pages.SUCCESS_PAGE.ACCOUNT_LOCK, command: security[command] };
        navigateToSuccess({ state })?.();
        setSubmitting(false);
      } catch ({ errorCode }) {
        if (errorCode === 'FGS1304') {
          return navigateToFailed({
            state: { from: pages.FAILED_PAGE.INVALID_PIN },
            callback: () => setSubmitting?.(false),
          })?.();
        }

        return navigateToFailed({
          state: { from: pages.FAILED_PAGE.ACCOUNT_LOCK, command: security[command] },
          callback: () => setSubmitting?.(false),
        })?.();
      }
    },
  });

  return (
    <form className="content" autoComplete="false" onSubmit={formik.handleSubmit}>
      <label className="block text-gray-800 mb-3">{t(data).SITE.LOCK.FORM.STEP_02.LABEL}</label>
      <ArticleStyled>
        <Input
          maxLength={4}
          mask={createMask('NUMBER', 4)}
          title={t(data).SECURITY.FORM.PIN}
          type={showPassword ? 'text' : 'password'}
          inputMode="numeric"
          value={formik.values.pin}
          errorMessage={formik.errors.pin}
          onChange={formik.handleChange('pin')}
          touchedInput={formik.touched.pin}
          onFocus={() => formik.setFieldTouched('pin', false)}
          pattern="[0-9]*"
          guide={false}
          css={css`
            input[type='password']::-webkit-credentials-auto-fill-button {
              visibility: hidden;
            }
          `}
          suffixIcon={
            !showPassword ? (
              <i className="icon-eye-stroke" />
            ) : (
              <i
                className="icon-eye-open"
                css={css`
                  &::before {
                    font-size: 0.8rem;
                    position: relative;
                    top: -2px;
                  }
                `}
              />
            )
          }
          suffixIconCallback={() => setShowPassword(!showPassword)}
        />
      </ArticleStyled>

      <ArticleStyled className="items-center">
        <Button color={'primary'} type="submit">{t(data).SECURITY.FORM.BUTTON}</Button>
      </ArticleStyled>
    </form>
  );
};

export { SecurityForm };
